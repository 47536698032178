import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./layouts/Loading";
import { useEffect, useState } from "react";
import MainMenu from "./layouts/MainMenu";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="h-screen flex justify-center align-middle app-background">
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" exact element={<MainMenu />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
