import React, { useState, useEffect, useRef } from "react";
import Animationa from "../assets/images/animationa.png";
import Animationb from "../assets/images/animationb.png";
import Animationc from "../assets/images/animationc.png";
import Animationd from "../assets/images/animationd.png";
import Animatione from "../assets/images/animatione.png";
import Animationf from "../assets/images/animationf.png";
import ImageIdle from "../assets/images/imageidle.png";
import bg1 from "../assets/giantmaps/1.jpg";
import bg2 from "../assets/giantmaps/2.jpg";
import bg3 from "../assets/giantmaps/3.jpg";
import bg4 from "../assets/giantmaps/4.jpg";
import bg5 from "../assets/giantmaps/5.jpg";
import btnActive from "../assets/giantmaps/goldbtn.png";
import btnDisable from "../assets/giantmaps/silverbtn.png";
import guidance from "../assets/giantmaps/guidance.jpeg";

function MainMenu() {
  const [selectedHall, setSelectedHall] = useState("");
  const [isIdle, setIsIdle] = useState(true);
  const [disabledButton, setDisabledButton] = useState(""); // Track disabled button
  const ws = useRef(null); // Ref to store WebSocket instance

  useEffect(() => {
    // Establish WebSocket connection
    ws.current = new WebSocket("wss://server-bcaexpo.senimankode.id/");

    ws.current.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      // Cleanup WebSocket connection on component unmount
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    let idleTimeout;

    if (selectedHall !== "") {
      setIsIdle(false);
      idleTimeout = setTimeout(() => {
        setIsIdle(true);
        setSelectedHall("");
        const message = { message: "Home" };
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify(message));
          console.log(
            `Sent message: ${JSON.stringify(message)} through WebSocket`
          );
        }
      }, 60000);
    }

    return () => clearTimeout(idleTimeout);
  }, [selectedHall]);

  const handleButtonClick = (hall) => {
    // Temporarily disable all buttons except the one clicked
    setDisabledButton(hall);

    // Delay to ensure the button is disabled before sending WebSocket message
    setTimeout(() => {
      // Send the WebSocket message
      const message = hall === "Home" ? { message: "Home" } : { message: hall };
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify(message));
        console.log(
          `Sent message: ${JSON.stringify(message)} through WebSocket`
        );
      }

      // Re-enable the buttons after 0.6 seconds
      setDisabledButton("");
      setSelectedHall(hall);
    }, 600); // 0.6 seconds delay
  };

  return (
    <div className="relative w-screen h-screen">
      <header className="flex justify-center items-center w-full"></header>
      <div className="flex w-full h-full justify-center items-center bg-slate-400 z-10">
        <div className="image-container relative w-full h-full">
          <img
            src={bg1}
            alt="Idle Animation"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "Home" || isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
          <img
            src={bg2}
            alt="Animation A"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "A" && !isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
          <img
            src={bg2}
            alt="Animation B"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "B" && !isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
          <img
            src={bg3}
            alt="Animation C"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "C" && !isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
          <img
            src={bg4}
            alt="Animation D"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "D" && !isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
          <img
            src={bg5}
            alt="Animation E"
            className={`transition-opacity duration-500 absolute top-0 left-0 w-full h-full object-cover ${
              selectedHall === "E" && !isIdle ? "opacity-100" : "opacity-0"
            }`}
          />
        </div>
        <button
          className="absolute bottom-[135px] scale-[0.8] h-[100px] w-[260px] text-[40px] text-[#352603] text-center rounded-lg transition-all duration-500"
          onClick={() => handleButtonClick("Home")}
          disabled={disabledButton && disabledButton !== "Home"}
          style={{
            backgroundImage: `url(${
              selectedHall === "Home" ? btnDisable : btnActive
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            fontFamily: "BCASans, sans-serif",
          }}
        >
          Home
        </button>
      </div>
      <footer className="absolute flex justify-center items-center bottom-5 w-full">
        <div className="space-x-[40px]">
          {["A", "B", "C", "D", "E"].map((hall) => {
            let displayText;
            switch (hall) {
              case "A":
                displayText = "5(1)";
                break;
              case "B":
                displayText = "5(2)";
                break;
              case "C":
                displayText = "6-7";
                break;
              case "D":
                displayText = "8-9";
                break;
              case "E":
                displayText = "10";
                break;
              default:
                displayText = hall;
            }
            return (
              <button
                key={hall}
                className={`h-[100px] w-[235px] text-[40px] text-[#352603] text-center rounded-lg transition-all duration-500`}
                onClick={() => handleButtonClick(hall)}
                disabled={disabledButton && disabledButton !== hall}
                style={{
                  backgroundImage: `url(${
                    selectedHall === hall ? btnDisable : btnActive
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  fontFamily: "BCASans, sans-serif",
                }}
              >
                Hall {displayText}
              </button>
            );
          })}
        </div>
      </footer>
    </div>
  );
}

export default MainMenu;
